<template>
  <div class="wh-space-bottom">
    <b-card>
      <table-search
        ref="search"
        v-model="filterValue"
        :fields="search_fields"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </b-card>

    <b-card>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :hide-search="false"
        :search-tips="$t('geo_fencing.search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        :permission="view_permission"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
      />
    </b-card>
  </div>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'

export default {
  name: 'geoFencingAntennaFacility',
  components: {
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('geoFencingAntennaFacility'),
      // is_admin: common.isAdmin(),
      view_permission: common.checkPermission('GeoFencing_Menu_Facility'),
      // view_permission: false,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'device_manage.mac',
          field: 'deviceMAC',
          width: '180px',
          changeHTML: (value, row) => `<span class="wh-font-family-monospace">${value.toUpperCase()}</span>`
        },
        {
          label: 'geo_fencing.antenna_name',
          field: 'deviceName',
          width: '180px',
        },
        {
          label: 'common.company',
          field: 'companyName',
          width: '200px',
        },
        {
          label: 'common.facility',
          field: 'facilityName',
          width: '200px',
        },
        {
          label: 'common.action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      header_actions: [],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'GeoFencing_Menu_Facility'
        },
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          // clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        // {
        //   field: 'floorID',
        //   label: 'common.floor',
        //   type: 'select',
        //   options: []
        // },
        // {
        //   field: 'roomID',
        //   label: 'common.room',
        //   type: 'select',
        //   options: []
        // },
        // {
        //   field: 'status',
        //   label: 'device_manage.status',
        //   type: 'select',
        //   options: [
        //     {
        //       text: common.getI18n('device_manage.status_on'),
        //       value: 'DSON'
        //     },
        //     {
        //       text: common.getI18n('device_manage.status_off'),
        //       value: 'DSOF'
        //     },
        //     {
        //       text: common.getI18n('device_manage.status_unset'),
        //       value: 'DSI'
        //     },
        //   ]
        // }

      ],
      filterValue: {
        // companyID: common.getCompanyId(),
        // facilityID: common.getFacilityId(),
        companyID: null,
        facilityID: null,
      }
    }
  },
  activated() {
    common.getSearchCompanyOptions('companyID', this)
    common.getSearchFacilityOptions('facilityID', this.filterValue.companyID, this)
    // common.getSearchFloorOptions('floorID', this.filterValue.facilityID, this)
    this.getList()
  },
  deactivated() {
    this.$refs.search.resetFilterValueToFiltered()
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const params = Object.assign({}, this.getSearchCondition(), this.sort_condition, this.page_condition)
      if (params.companyID && !params.facilityID) {
        params.facilityID = -1
      }
      this.$requestWehealth({
        method: 'get',
        url: '/geofencing/GetFacilityGeoFencingList',
        params
      })
        .then(res => {
          this.total_rows = res.data.total
          this.rows = res.data.geoFencings
        })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    // addData: function () {
    //   this.$router.push({ name: 'callBellFacility_add' })
    // },
    editData: function (data) {
      console.log(data)
      this.$router.push({
        name: 'geoFencingAntennaFacility_edit',
        params: { id: common.encrypt(data.geoFencingID) }
      })
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
  }
}
</script>
